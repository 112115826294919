import { Component, OnInit } from '@angular/core';
import { IReport } from 'src/app/models/Interfaces/IReport';
import { ReportService } from '../../services/report.service';
import { IAdCost } from '../../models/Interfaces/IAdCost';
import { AdCostService } from 'src/app/services/ad-cost.service';
import { IAlertSetup } from 'src/app/models/IAlertSetup';
import { AlertSetup } from 'src/app/models/AlertSetup';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
  selectedReportsIndex: number;
  reports: IReport[] = [];
  showReports: boolean = false;
  showLoader: boolean = true;
  showInfo: boolean = false;
  infoMessage: string = '';
  alert: IAlertSetup = new AlertSetup();

  constructor(
    private _reportService: ReportService,
    private _adCostService: AdCostService
  ) {}

  private descendingByDate(a: IReport, b: IReport): number {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  }

  private reportSoring(reports: IReport[]): IReport[] {
    let result: IReport[] = [];

    if (reports.length === 0) return reports;
    if (reports.length === 1) return reports;

    return reports.sort(this.descendingByDate);
  }

  loadReport(): void {
    this._reportService.GetReport()
      .subscribe((response) => {
        this.reports = this.reportSoring(response);
        this.showReports = true;
        this.showLoader = false;
      }, (error) => {
        this.showLoader = false;
        this.showInfo = true;
        this.infoMessage = 'Riport nem található, vagy nem tölthető be.';
        this.alert = new AlertSetup({
          show: true,
          message: this.infoMessage,
          alertType: AlertTypes.warning,
        });
        console.error(error);
      })
  }

  loadAdCost(): void {
    this._adCostService.getAdCosts()
      .subscribe((response) => {
        this.appendAdCosts(response, this.reports);
      }, (error) => {
        this.showInfo = true;
        this.infoMessage = 'AdCost adat nem található, vagy nem tölthető be.';
        this.alert = new AlertSetup({
          show: true,
          message: this.infoMessage,
          alertType: AlertTypes.info,
        });
        console.error(error);
      })
  }

  private appendAdCosts(adCosts: IAdCost[], reports: IReport[]): void {
    for (let cost of adCosts) {
      for (let report of reports) {
        if (report.date.toString() === cost.yearMonthGroup.toString()) {
          for (let row of report.rows) {
            if (row.source_medium === cost.sourceMedium) {
              row.marketing_cost = cost.cost;
              row.financial_balance = row.revenue_sum - row.marketing_cost;
            }
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.loadReport();
    this.loadAdCost();
  }
}
