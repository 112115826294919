import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ExcelUploadService } from '../../services/excel-upload.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { IAlertSetup } from '../../models/IAlertSetup';
import { AlertSetup } from '../../models/AlertSetup';
import { AlertTypes } from 'src/app/Enums/AlertTypes.enum';
import { IExcelRow } from '../../models/Interfaces/IExcelRow';
import { ActivatedRoute } from '@angular/router';
import { IExcelUploadLog } from '../../models/Interfaces/IExcelUploadLog';
import { DataPayloadSignalRService } from 'src/app/services/SignalR/data-payload-signal-r.service';
import { IPhase } from '../../models/IPhase';
import { IPhaseResult } from '../../models/IPhaseResult';
import { ExcelUploadLog } from 'src/app/models/ExcelUploadLog';
import { ConfigService } from 'src/app/Services/config.service';
import { map } from 'rxjs/operators';
import { ExcelRow } from 'src/app/models/ExcelRow';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  providers: [DataPayloadSignalRService, { provide: ProgressbarConfig }],
})
export class UploadComponent implements OnInit {
  alert: IAlertSetup = new AlertSetup();
  phase: number = -1;
  showFileInput: boolean = true;
  last: ExcelUploadLog;

  @ViewChild('excelInput', { static: false })
  excelInput: ElementRef<HTMLInputElement>;

  constructor(
    private _excelUpload: ExcelUploadService,
    private _route: ActivatedRoute,
    public _signalR: DataPayloadSignalRService,
    private _http: HttpClient,
    private _config: ConfigService
  ) {}

  invalidUploadRows: IExcelRow[] = [];
  logs: IExcelUploadLog[] = [];
  showLog: boolean = false;

  excelInputLabelShowFileName(file) {
    const label = this.excelInput.nativeElement.labels[0];
    label.innerHTML = file[0].name;
  }

  excelInputLabelSetDefaultText() {
    const text = "Válaszd ki az excel file-t!";
    const label = this.excelInput.nativeElement.labels[0];
    label.innerHTML = text;
  }

  upload(): void {
    this.refreshLogs();
    this.alert = new AlertSetup();
    this.last = null;
    this.showFileInput = false;
    this._signalR.ListenUploadStatus((data: IPhase | IPhaseResult) => {
      console.log(data);
      if(this._signalR.Phase === -2)
      {
        let result = data as IPhaseResult;
        this.alert = new AlertSetup({
          show: true,
          message: `Error: ${result.description}`,
          alertType: AlertTypes.danger,
        });
      }
// (change)="excelInputLabelShowFileName($event.target.files)"
      if (this._signalR.Phase === 3) {              
        let result = data as IPhaseResult;
        this.setChangedReportIndicator(result);        

        this.getLast();
        this.refreshLogs();
        this.showFileInput = true;
        this.excelInputLabelSetDefaultText();
      }
    
    });

    const file = this.excelInput.nativeElement.files[0];

    const formData = new FormData();
    formData.append('File', file);
    this.httpPost(formData).subscribe(
      (response) => {
        this.invalidUploadRows = response;
      },
      (error: HttpErrorResponse) => {
        this.alert = new AlertSetup({
          show: true,
          message: `Feltöltés sikertelen`,
          alertType: AlertTypes.success,
        });
      }
    );
  }

  setChangedReportIndicator(result: IPhaseResult) {
    let message: string = '';
    console.log(result.changedReports.length);
    if(result.changedReports.length === 0) {
      this.alert = new AlertSetup({
        show: true,
        message: `Nem történt változás a riportokban`,
        alertType: AlertTypes.warning,
      });
      return;
    }

    for (let date of result.changedReports) {
      message += date + '; ';
    }
    this.alert = new AlertSetup({
      show: true,
      message: `Változás a következő riportokban: ${message}`,
      alertType: AlertTypes.success,
    });
  }

  httpPost = (formData: FormData) => {
    return this._http
    .post<IExcelRow[]>(`${this._config.apiUrl}/api/Excel`, formData)
    .pipe(
      map((response: any) => {
        let result: IExcelRow[] = [];
        for (let excelRow of response.rows) {
          result.push(new ExcelRow(excelRow));
        }

        return result;
      })
    );
  }

  public refreshLogs(): void {
    this._excelUpload.GetLogs().subscribe((response: IExcelUploadLog[]) => {
      this.logs = response;
      this.showLog = true;
    });
  }

  private getLogs(): void {
    this._route.data.subscribe((data: []) => {
      this.logs = data['logs'];
    });
  }

  private getLast(): void {
    this._excelUpload.Last().subscribe(
      (res) => {
        this.last = res;
      },
      (err: HttpErrorResponse) => console.error(err)
    );
  }

  ngOnInit(): void {
    this.refreshLogs();
    this._signalR.StartConnection();
  }
}
