<app-alert [alert]="alert"></app-alert>
<div class="row" *ngIf="showReports">
  <div class="col-12">
    <accordion [isAnimated]="true">
      <accordion-group
        *ngFor="let report of reports; let i = index"
        [isOpen]="i === 0"
        class="mb-3"
      >
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">
            <i class="fas fa-2x fa-chart-line"></i>
          </div>
          <span class="h4 report-title">
            Online marketing csatornák teljesítménye -
            {{ report.date | date_for_report_list }}
          </span>
        </button>
        <div class="embed-responsive mb-5">
          <div>
            <tabset>
              <tab heading="Táblázat">
                <app-report-data-show-table
                  [report]="report"
                ></app-report-data-show-table>
              </tab>
              <tab heading="Info grafika">
                <app-report-info-graphics
                  [i]="i"
                  [report]="report"
                ></app-report-info-graphics>
              </tab>
            </tabset>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
</div>
  
<div *ngIf="showLoader" class="loader">
    <span class="indicator">Report loading ... </span>
    <img src="../../../assets/img/ajax-loader.gif" alt="" />
</div>


