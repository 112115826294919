import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { BehaviorSubject } from 'rxjs';

enum EditedCellStateEnum {
  untouched = 'untouched',
  setAdCost = 'setAdCost',
  touched = 'touched',
  edit = 'edit',
}

@Component({
  selector: 'app-edited-cell',
  templateUrl: './edited-cell.component.html',
  styleUrls: ['./edited-cell.component.css'],
})
export class EditedCellComponent implements OnInit, OnChanges {
  state: string = EditedCellStateEnum.untouched;
  toastId: number;
  isInvalid: boolean = false;
  valueTemp: number;
  @Input() cellResetBehavior: BehaviorSubject<boolean>;
  @Input() fieldName: string = '';
  @Input() cost: number = 0;
  @Output() setMarketingCost: EventEmitter<number> = new EventEmitter<number>();
  @Output() reset: EventEmitter<null> = new EventEmitter<null>();

  constructor(private _toast: ToastService, private vref: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cost.currentValue !== 0 && changes.cost.firstChange) {
      this.state = EditedCellStateEnum.setAdCost;
    }
  }

  ngOnInit(): void {
    this.cellResetBehavior.subscribe((value: any) => {
      //this.setReset();
    });
  }

  setValue(): void {
    this.isInvalid = false;
    const inputCost = document.getElementById(
      'inputMarketingCost'
    ) as HTMLInputElement;
    inputCost.focus();

    if (Number.isNaN(+inputCost.value)) {
      this.isInvalid = true;
      this.toastId = this._toast.Error(
        `A ${this.fieldName} mezőbe csak egész szám adható meg!`
      );
      return;
    }

    if (this.valueTemp === +inputCost.value) {
      this.state = EditedCellStateEnum.untouched;
      return;
    }

    if (+inputCost.value !== 0) {
      this.state = EditedCellStateEnum.touched;
    }

    this.setMarketingCost.emit(+inputCost.value);
    this.state = EditedCellStateEnum.touched;
  }

  dropEdit(): void {
    if (this.valueTemp !== this.cost) {
      this.state = EditedCellStateEnum.touched;
    } else {
      this.state = EditedCellStateEnum.untouched;
    }
    this.isInvalid = false;
  }

  setEdit(): void {
    this.valueTemp = this.cost;
    this.state = EditedCellStateEnum.edit;
  }

  setReset(): void {
    this.state = EditedCellStateEnum.untouched;
    this.reset.emit(null);
  }

  cancelEditWarning(): void {
    console.log('close');
    let popover: HTMLDivElement = document.querySelector('.bs-popover-top');
    console.log(popover);
  }
}
