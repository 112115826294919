<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <button
          class="btn btn-sm btn-outline-secondary"
          type="button"
          (click)="printTable(CreateReportTableId(report.date, index))"
        >
          <i class="fas fa-print"></i>
          &nbsp;Nyomtatás
        </button>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input
        class="form-control mr-sm-2"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">
        Search
      </button>
    </form>
  </div>
</nav>
<div class="report-table-wrapper">
  <table
    [id]="CreateReportTableId(report.date, index)"
    class="table table-striped imp-show-report-table"
  >
    <thead class="imp-bi-report-thead">
      <tr>
        <th scope="col">Forrás/médium</th>
        <th scope="col">Online konverziók</th>
        <th scope="col">Keletkezett leadek</th>
        <th scope="col">Offline konverziók</th>
        <th scope="col">
          Bevétel
          <app-question-helper
            [helpText]="
              'Offline eladásból származó bevétel csatornához köthető része'
            "
          ></app-question-helper>
        </th>
        <th scope="col">Marketing költség</th>
        <th scope="col">Pénzügyi egyenleg</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of report.rows">
        <th scope="row">{{ row.source_medium }}</th>
        <td>{{ row.online_conversions }}</td>
        <td>{{ row.generated_leads }}</td>
        <td >
          <span
            *ngIf="row.offline_converisons > 0"
            [tooltip]="convToString(row.offline_converisons)"
          >
            {{ row.offline_converisons | decimal }}
          </span>
          <span
            *ngIf="row.offline_converisons === 0"
          >
            {{ row.offline_converisons | decimal }}
          </span>
        </td>
        <td>{{ row.revenue_sum | money }} HUF</td>
        <td>
          <app-edited-cell
            [cost]="row.marketing_cost"
            [fieldName]="'Marketing költség'"
            [cellResetBehavior]="cellResetBehavior"
            (setMarketingCost)="setMarketingCost($event, row)"
            (reset)="resetMarketingCost(row)"
          ></app-edited-cell>
        </td>

        <td [ngClass]="resultDependCellColoring(row.financial_balance)">
          {{ row.financial_balance | money }} HUF
        </td>
      </tr>
      <tr class="imp-bi-report-table-sum-row">
        <th scope="row">Összegzés:</th>
        <td>{{ report.sum().online_conversions }}</td>
        <td>{{ report.sum().generated_leads }}</td>
        <td>
          <span 
            *ngIf="report.sum().offline_converisons > 0"  
            [tooltip]="convToString(report.sum().offline_converisons)"            
            >
            {{ report.sum().offline_converisons | decimal }}
          </span>
          <span 
            *ngIf="report.sum().offline_converisons === 0"  
            >
            {{ report.sum().offline_converisons | decimal }}
          </span>
        </td>
        <td>{{ report.sum().revenue_sum | money }} HUF</td>
        <td>
          {{ report.sum().marketing_cost | money }} HUF
          <button
            *ngIf="!untouched"
            class="btn btn-light print-hidden"
            type="button"
            (click)="setReset()"
            popover="Reset"
            triggers="hover"
          >
            <i class="fas fa-trash-alt" style="color: red"></i>
          </button>
        </td>
        <td
          [ngClass]="resultDependCellColoring(report.sum().financial_balance)"
        >
          {{ report.sum().financial_balance | money }} HUF
        </td>
      </tr>
    </tbody>
  </table>
</div>
