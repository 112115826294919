<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 998.4 557.5"
  style="enable-background: new 0 0 998.4 557.5"
  xml:space="preserve"
>
  <g title="background gradient">
    <linearGradient
      id="SVGID_1_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="499.25"
      y1="2.2"
      x2="499.25"
      y2="559.4"
      gradientTransform="matrix(1 0 0 -1 0 560)"
    >
      <stop offset="0" style="stop-color: #000000" />
      <stop offset="1" style="stop-color: #000050" />
    </linearGradient>
    <rect
      x="0.1"
      y="0.6"
      class="st0"
      width="998.3"
      height="557.2"
      attr.fill="url(#SVGID_1_{{ i }})"
    />
    <linearGradient
      id="SVGID_2_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="0"
      y1="903.05"
      x2="483.7"
      y2="903.05"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #4032b7" />
      <stop offset="0.1646" style="stop-color: #3c2fb1" />
      <stop offset="0.3884" style="stop-color: #31279f" />
      <stop offset="0.6461" style="stop-color: #201983" />
      <stop offset="0.9272" style="stop-color: #07065b" />
      <stop offset="1" style="stop-color: #000050" />
    </linearGradient>
    <rect
      y="348.6"
      class="st1"
      attr.fill="url('#SVGID_2_{{ i }}')"
      width="483.7"
      height="68.9"
    />
    <linearGradient
      id="SVGID_3_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="0"
      y1="812.05"
      x2="483.7"
      y2="812.05"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #00b1ff" />
      <stop offset="1" style="stop-color: #000050" />
    </linearGradient>
    <rect
      y="257.6"
      class="st2"
      attr.fill="url('#SVGID_3_{{ i }}')"
      width="483.7"
      height="68.9"
    />
    <linearGradient
      id="SVGID_4_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="0"
      y1="990.45"
      x2="483.7"
      y2="990.45"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #a12238" />
      <stop offset="0.1257" style="stop-color: #9b2139" />
      <stop offset="0.2919" style="stop-color: #8a1d3b" />
      <stop offset="0.4808" style="stop-color: #6f173f" />
      <stop offset="0.6865" style="stop-color: #490f45" />
      <stop offset="0.9033" style="stop-color: #18054c" />
      <stop offset="1" style="stop-color: #000050" />
    </linearGradient>
    <rect
      y="436"
      class="st3"
      attr.fill="url('#SVGID_4_{{ i }}')"
      width="483.7"
      height="68.9"
    />
  </g>

  <g>
    <linearGradient
      id="SVGID_5_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="516.0605"
      y1="1034.6285"
      x2="459.8733"
      y2="937.3093"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="9.669167e-02" style="stop-color: #18054c" />
      <stop offset="0.3135" style="stop-color: #490f45" />
      <stop offset="0.5192" style="stop-color: #6f173f" />
      <stop offset="0.7081" style="stop-color: #8a1d3b" />
      <stop offset="0.8743" style="stop-color: #9b2139" />
      <stop offset="1" style="stop-color: #a12238" />
    </linearGradient>
    <path
      class="st4"
      attr.fill="url('#SVGID_5_{{ i }}')"
      d="M540,436H427.5l14.9,70.6c1.5,7.2,19.4,12.8,41.4,12.8s39.9-5.7,41.4-12.8L540,436z"
    />

    <linearGradient
      id="SVGID_6_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="427.4"
      y1="955.4"
      x2="540"
      y2="955.4"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #a12238" />
    </linearGradient>
    <ellipse
      class="st5"
      attr.fill="url('#SVGID_6_{{ i }}')"
      cx="483.7"
      cy="435.4"
      rx="56.3"
      ry="18.7"
    />

    <linearGradient
      id="SVGID_7_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="519.7766"
      y1="954.2045"
      x2="455.9807"
      y2="843.7067"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="7.280421e-02" style="stop-color: #07065b" />
      <stop offset="0.3539" style="stop-color: #201983" />
      <stop offset="0.6116" style="stop-color: #31279f" />
      <stop offset="0.8354" style="stop-color: #3c2fb1" />
      <stop offset="1" style="stop-color: #4032b7" />
    </linearGradient>
    <path
      class="st6"
      attr.fill="url('#SVGID_7_{{ i }}')"
      d="M558,350.6H409.4l13.9,65.5c2.1,10.5,28.4,18.7,60.4,18.7c32.1,0,58.3-8.3,60.4-18.7L558,350.6z"
    />

    <linearGradient
      id="SVGID_8_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="409.2"
      y1="868.6"
      x2="558.2"
      y2="868.6"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #4032b7" />
    </linearGradient>
    <ellipse
      class="st7"
      attr.fill="url('#SVGID_8_{{ i }}')"
      cx="483.7"
      cy="348.6"
      rx="74.5"
      ry="24.7"
    />

    <linearGradient
      id="SVGID_9_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="447.0734"
      y1="722.5506"
      x2="531.4229"
      y2="868.6483"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #00b1ff" />
      <stop offset="1" style="stop-color: #000050" />
    </linearGradient>
    <path
      class="st8"
      attr.fill="url('#SVGID_9_{{ i }}')"
      d="M582,238.1H385.5l18.3,86.6c2.8,13.8,37.5,24.7,79.9,24.7s77.1-10.9,79.9-24.7L582,238.1z"
    />

    <linearGradient
      id="SVGID_10_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="455.2896"
      y1="804.8084"
      x2="512.1105"
      y2="706.3917"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #00b1ff" />
    </linearGradient>
    <ellipse
      class="st9"
      attr.fill="url('#SVGID_10_{{ i }}')"
      cx="483.7"
      cy="235.6"
      rx="98.5"
      ry="32.7"
    />
  </g>
  <g>
    <linearGradient
      id="SVGID_11_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="721.3981"
      y1="1034.6068"
      x2="665.2108"
      y2="937.2876"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="9.669167e-02" style="stop-color: #18054c" />
      <stop offset="0.3135" style="stop-color: #490f45" />
      <stop offset="0.5192" style="stop-color: #6f173f" />
      <stop offset="0.7081" style="stop-color: #8a1d3b" />
      <stop offset="0.8743" style="stop-color: #9b2139" />
      <stop offset="1" style="stop-color: #a12238" />
    </linearGradient>
    <path
      class="st14"
      attr.fill="url('#SVGID_11_{{ i }}')"
      d="M745.4,436H632.8l14.9,70.6c1.5,7.2,19.4,12.8,41.4,12.8s39.9-5.7,41.4-12.8L745.4,436z"
    />

    <linearGradient
      id="SVGID_12_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="632.8"
      y1="955.4"
      x2="745.4"
      y2="955.4"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #a12238" />
    </linearGradient>
    <ellipse
      class="st15"
      attr.fill="url('#SVGID_12_{{ i }}')"
      cx="689.1"
      cy="435.4"
      rx="56.3"
      ry="18.7"
    />

    <linearGradient
      id="SVGID_13_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="725.1766"
      y1="954.2045"
      x2="661.3807"
      y2="843.7067"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="7.280421e-02" style="stop-color: #07065b" />
      <stop offset="0.3539" style="stop-color: #201983" />
      <stop offset="0.6116" style="stop-color: #31279f" />
      <stop offset="0.8354" style="stop-color: #3c2fb1" />
      <stop offset="1" style="stop-color: #4032b7" />
    </linearGradient>
    <path
      class="st16"
      attr.fill="url('#SVGID_13_{{ i }}')"
      d="M763.4,350.6H614.8l13.9,65.5c2.1,10.5,28.4,18.7,60.4,18.7c32.1,0,58.3-8.3,60.4-18.7L763.4,350.6z"
    />

    <linearGradient
      id="SVGID_14_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="614.6"
      y1="868.6"
      x2="763.6"
      y2="868.6"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #4032b7" />
    </linearGradient>
    <ellipse
      class="st17"
      attr.fill="url('#SVGID_14_{{ i }}')"
      cx="689.1"
      cy="348.6"
      rx="74.5"
      ry="24.7"
    />

    <linearGradient
      id="SVGID_15_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="652.4734"
      y1="722.5506"
      x2="736.8229"
      y2="868.6483"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #00b1ff" />
      <stop offset="1" style="stop-color: #000050" />
    </linearGradient>
    <path
      class="st18"
      attr.fill="url('#SVGID_15_{{ i }}')"
      d="M787.4,238.1H590.9l18.3,86.6c2.8,13.8,37.5,24.7,79.9,24.7s77.1-10.9,79.9-24.7L787.4,238.1z"
    />

    <linearGradient
      id="SVGID_16_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="660.6896"
      y1="804.8083"
      x2="717.5104"
      y2="706.3917"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #00b1ff" />
    </linearGradient>
    <ellipse
      class="st19"
      attr.fill="url('#SVGID_16_{{ i }}')"
      cx="689.1"
      cy="235.6"
      rx="98.5"
      ry="32.7"
    />
  </g>
  <g>
    <linearGradient
      id="SVGID_17_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="927.2606"
      y1="1034.6285"
      x2="871.0734"
      y2="937.3093"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="9.669167e-02" style="stop-color: #18054c" />
      <stop offset="0.3135" style="stop-color: #490f45" />
      <stop offset="0.5192" style="stop-color: #6f173f" />
      <stop offset="0.7081" style="stop-color: #8a1d3b" />
      <stop offset="0.8743" style="stop-color: #9b2139" />
      <stop offset="1" style="stop-color: #a12238" />
    </linearGradient>
    <path
      class="st20"
      attr.fill="url('#SVGID_17_{{ i }}')"
      d="M951.2,436H838.7l14.9,70.6c1.5,7.2,19.4,12.8,41.4,12.8s39.9-5.7,41.4-12.8L951.2,436z"
    />

    <linearGradient
      id="SVGID_18_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="838.7"
      y1="955.4"
      x2="951.3"
      y2="955.4"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #a12238" />
    </linearGradient>
    <ellipse
      class="st21"
      attr.fill="url('#SVGID_18_{{ i }}')"
      cx="895"
      cy="435.4"
      rx="56.3"
      ry="18.7"
    />

    <linearGradient
      id="SVGID_19_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="931.0767"
      y1="954.2045"
      x2="867.2806"
      y2="843.7067"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="7.280421e-02" style="stop-color: #07065b" />
      <stop offset="0.3539" style="stop-color: #201983" />
      <stop offset="0.6116" style="stop-color: #31279f" />
      <stop offset="0.8354" style="stop-color: #3c2fb1" />
      <stop offset="1" style="stop-color: #4032b7" />
    </linearGradient>
    <path
      class="st22"
      attr.fill="url('#SVGID_19_{{ i }}')"
      d="M969.3,350.6H820.7l13.9,65.5c2.1,10.5,28.4,18.7,60.4,18.7c32.1,0,58.3-8.3,60.4-18.7L969.3,350.6z"
    />

    <linearGradient
      id="SVGID_20_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="820.5"
      y1="868.6"
      x2="969.5"
      y2="868.6"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #4032b7" />
    </linearGradient>
    <ellipse
      class="st23"
      attr.fill="url('#SVGID_20_{{ i }}')"
      cx="895"
      cy="348.6"
      rx="74.5"
      ry="24.7"
    />

    <linearGradient
      id="SVGID_21_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="858.2734"
      y1="722.5506"
      x2="942.6229"
      y2="868.6483"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #00b1ff" />
      <stop offset="1" style="stop-color: #000050" />
    </linearGradient>
    <path
      class="st24"
      attr.fill="url('#SVGID_21_{{ i }}')"
      d="M993.2,238.1H796.7l18.3,86.6c2.8,13.8,37.5,24.7,79.9,24.7s77.1-10.9,79.9-24.7L993.2,238.1z"
    />

    <linearGradient
      id="SVGID_22_{{ i }}"
      gradientUnits="userSpaceOnUse"
      x1="866.5895"
      y1="804.8084"
      x2="923.4105"
      y2="706.3916"
      gradientTransform="matrix(1 0 0 1 0 -520)"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="1" style="stop-color: #00b1ff" />
    </linearGradient>
    <ellipse
      class="st25"
      attr.fill="url('#SVGID_22_{{ i }}')"
      cx="895"
      cy="235.6"
      rx="98.5"
      ry="32.7"
    />

    <radialGradient
      id="SVGID_23_{{ i }}"
      cx="482.7292"
      cy="828.7"
      r="96.7304"
      gradientTransform="matrix(1 0 0 1 0 -520)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="0.1233" style="stop-color: #3b3b79" />
      <stop offset="0.2468" style="stop-color: #6f6f9c" />
      <stop offset="0.3719" style="stop-color: #9b9bba" />
      <stop offset="0.497" style="stop-color: #bfbfd3" />
      <stop offset="0.6221" style="stop-color: #dbdbe6" />
      <stop offset="0.7475" style="stop-color: #efeff4" />
      <stop offset="0.8732" style="stop-color: #fbfbfc" />
      <stop offset="1" style="stop-color: #ffffff" />
    </radialGradient>
    <path
      class="st26"
      attr.fill="url('#SVGID_23_{{ i }}')"
      d="M483.7,268.2c29.7,0,56.4-4.4,74.5-11.3V48.4c0-5.3-4.3-9.6-9.6-9.6H418.9c-5.3,0-9.6,4.3-9.6,9.6V257
	   C427.3,263.9,454,268.2,483.7,268.2z"
    />

    <radialGradient
      id="SVGID_24_{{ i }}"
      cx="688.0989"
      cy="827.6985"
      r="96.7303"
      gradientTransform="matrix(1 0 0 1 0 -520)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="0.1233" style="stop-color: #3b3b79" />
      <stop offset="0.2468" style="stop-color: #6f6f9c" />
      <stop offset="0.3719" style="stop-color: #9b9bba" />
      <stop offset="0.497" style="stop-color: #bfbfd3" />
      <stop offset="0.6221" style="stop-color: #dbdbe6" />
      <stop offset="0.7475" style="stop-color: #efeff4" />
      <stop offset="0.8732" style="stop-color: #fbfbfc" />
      <stop offset="1" style="stop-color: #ffffff" />
    </radialGradient>
    <path
      class="st27"
      attr.fill="url('#SVGID_24_{{ i }}')"
      d="M689.1,268.2c29.7,0,56.4-4.4,74.5-11.3V48.4c0-5.3-4.3-9.6-9.6-9.6H624.3c-5.3,0-9.6,4.3-9.6,9.6V257
	   C632.7,263.9,659.4,268.2,689.1,268.2z"
    />

    <radialGradient
      id="SVGID_25_{{ i }}"
      cx="895.9573"
      cy="829.7014"
      r="96.7304"
      gradientTransform="matrix(1 0 0 1 0 -520)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style="stop-color: #000050" />
      <stop offset="0.1233" style="stop-color: #3b3b79" />
      <stop offset="0.2468" style="stop-color: #6f6f9c" />
      <stop offset="0.3719" style="stop-color: #9b9bba" />
      <stop offset="0.497" style="stop-color: #bfbfd3" />
      <stop offset="0.6221" style="stop-color: #dbdbe6" />
      <stop offset="0.7475" style="stop-color: #efeff4" />
      <stop offset="0.8732" style="stop-color: #fbfbfc" />
      <stop offset="1" style="stop-color: #ffffff" />
    </radialGradient>
    <path
      class="st28"
      attr.fill="url('#SVGID_25_{{ i }}')"
      d="M895,268.2c29.7,0,56.4-4.4,74.5-11.3V48.4c0-5.3-4.3-9.6-9.6-9.6H830.1c-5.3,0-9.6,4.3-9.6,9.6V257
	   C838.5,263.9,865.2,268.2,895,268.2z"
    />
  </g>
  <g>
    <path
      class="st29"
      d="M914,59.2c1.3-0.6,2.5-0.7,3.8-0.2c1.2,0.5,2.1,1.4,2.7,2.7c0.5,1.3,0.5,2.6-0.1,3.8L901,107.6
	   c-0.7,1.5-1.9,2.4-3.3,2.7c-1.5,0.3-2.8,0-4.1-0.9c-1.2-0.9-1.9-2.2-1.9-3.8V87.8h-17.8c-1.7,0-3-0.6-3.8-1.9
	   c-0.9-1.2-1.2-2.6-0.9-4.1s1.2-2.6,2.7-3.3L914,59.2z"
    />
  </g>
  <g>
    <text transform="matrix(1 0 0 1 41.4359 289.0695)" class="st10 st30 st31">
      KONVERZIÓK
    </text>
    <text transform="matrix(1 0 0 1 41.4359 307.0695)" class="st10 st32 st33">
      Darab
    </text>
  </g>
  <g>
    <text transform="matrix(1 0 0 1 45.7563 106.5504)" class="st10 st30 st31">
      KIADÁS
    </text>
    <text transform="matrix(1 0 0 1 45.7563 124.5504)" class="st10 st32 st33">
      Nettó Forint
    </text>
    <text transform="matrix(1 0 0 1 45.7561 165.6797)" class="st10 st30 st31">
      BEVÉTEL
    </text>
    <text transform="matrix(1 0 0 1 45.7561 183.6797)" class="st10 st32 st33">
      Nettó Forint
    </text>
  </g>
  <g>
    <g></g>
  </g>
  <g></g>
  <g>
    <text transform="matrix(1 0 0 1 41.4359 378.9973)" class="st10 st30 st31">
      LEADEK
    </text>
    <text transform="matrix(1 0 0 1 41.4359 396.9973)" class="st10 st32 st33">
      Darab
    </text>
  </g>
  <g>
    <text transform="matrix(1 0 0 1 41.4359 465.7673)" class="st10 st30 st31">
      OFFLINE ELADÁSOK
    </text>
    <text transform="matrix(1 0 0 1 41.4359 483.7673)" class="st10 st32 st33">
      Darab
    </text>
  </g>
  <g>
    <g>
      <path
        class="st37"
        d="M712.9,104.7c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6h-9.8V89.9h6.9l1-7.8h-7.9v-4.9
		   c0-1.2,0.2-2.1,0.7-2.8c0.6-0.7,1.6-1,3.1-1h4.1v-6.9c-1.6-0.2-3.6-0.3-6-0.3c-3.1,0-5.5,0.9-7.3,2.7c-1.8,1.8-2.7,4.3-2.7,7.5
		   v5.7h-7v7.8h7v20.3h-22.6c-1.5,0-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9V64.2c0-1.5,0.5-2.8,1.6-3.9c1.1-1.1,2.4-1.6,3.9-1.6
		   h40.4c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9L712.9,104.7L712.9,104.7z"
      />
    </g>
    <text transform="matrix(1 0 0 1 652.2504 138.3893)" class="st30 st31">
      Facebook
    </text>
  </g>
  <g>
    <g>
      <path
        class="st38"
        d="M459.4,108.9L459.4,108.9c-4.7-2.7-6.3-8.8-3.6-13.4l19.5-33.7c2.7-4.7,8.8-6.3,13.4-3.6l0,0
		   c4.7,2.7,6.3,8.8,3.6,13.4l-19.5,33.7C470.1,110,464.1,111.6,459.4,108.9z"
      />
      <path
        class="st39"
        d="M508.2,108.8L508.2,108.8c-4.7,2.7-10.7,1.1-13.4-3.6l-19.5-33.7c-2.7-4.7-1.1-10.7,3.6-13.4l0,0
		   c4.7-2.7,10.7-1.1,13.4,3.6l19.5,33.7C514.6,100,512.9,106,508.2,108.8z"
      />
      <circle class="st40" cx="464.4" cy="100.2" r="10" />
    </g>
    <text transform="matrix(1 0 0 1 440.3075 138.3894)" class="st30 st31">
      Google Ads
    </text>
  </g>
  <text transform="matrix(1 0 0 1 835.7456 138.3894)" class="st30 st31">
    Egyéb források
  </text>
  <text transform="matrix(1 0 0 1 274.3399 279.6336)" class="st10 st32 st33">
    Összesen
  </text>
  <text transform="matrix(1 0 0 1 310.4859 149.3531)" class="st10 st32 st33">
    Összesen
  </text>
  <text transform="matrix(1 0 0 1 45.7561 224.9096)" class="st10 st30 st31">
    EGYENLEG
  </text>
  <text transform="matrix(1 0 0 1 45.7561 242.9096)" class="st10 st32 st33">
    Nettó Forint
  </text>
  <g></g>
  <text transform="matrix(1 0 0 1 310.4859 208.583)" class="st10 st32 st33">
    Összesen
  </text>
  <text transform="matrix(1 0 0 1 310.4859 89.6559)" class="st10 st32 st33">
    Összesen
  </text>
  <text transform="matrix(1 0 0 1 274.3399 369.5615)" class="st10 st32 st33">
    Összesen
  </text>
  <text transform="matrix(1 0 0 1 274.3399 456.3315)" class="st10 st32 st33">
    Összesen
  </text>

  <!-- TEXT -->
  <g title="Google Ads column">
    <g title="Google Ads SUM card">
      <text
        title="kiadas"
        text-anchor="end"
        transform="matrix(1 0 0 1 548.6639 182.0931)"
        class="st34 st11 st45"
      >
        {{ report.convertForInfoGraphics().googleAds.marketing_cost | money }}
      </text>
      <text
        title="bevetel"
        text-anchor="end"
        transform="matrix(1 0 0 1 548.6639 211.9341)"
        class="st36 st11 st45"
      >
        {{ report.convertForInfoGraphics().googleAds.revenue_sum | money }}
      </text>
      <text
        title="egyenleg"
        text-anchor="end"
        transform="matrix(1 0 0 1 548.6636 241.7745)"
        class="st41 st11 st45"
      >
        {{
          report.convertForInfoGraphics().googleAds.financial_balance | money
        }}
      </text>
    </g>
    <g title="funnel">
      <text
        title="konzerziok"
        text-anchor="middle"
        transform="matrix(1 0 0 1 481.7328 318.9212)"
        class="st10 st11 st12"
      >
        {{ report.convertForInfoGraphics().googleAds.online_conversions }}
      </text>
      <text
        title="leadek"
        text-anchor="middle"
        transform="matrix(1 0 0 1 481.7327 414.6606)"
        class="st10 st11 st12"
      >
        {{ report.convertForInfoGraphics().googleAds.generated_leads }}
      </text>
      <text
        title="offline-eladasok"
        text-anchor="middle"
        transform="matrix(1 0 0 1 481.7328 493.8533)"
        class="st10 st11 st12"
      >
        {{
          report.convertForInfoGraphics().googleAds.offline_converisons
            | decimal
        }}
      </text>
    </g>
  </g>
  <g title="Other sources column">
    <g title="Other sources SUM card">
      <text
        text-anchor="end"
        title="kiadas"
        transform="matrix(1 0 0 1 959.1494 182.0931)"
        class="st34 st11 st45"
      >
        {{
          report.convertForInfoGraphics().otherSources.marketing_cost | money
        }}
      </text>
      <text
        text-anchor="end"
        title="bevetel"
        transform="matrix(1 0 0 1 959.1494 211.9341)"
        class="st36 st11 st45"
      >
        {{ report.convertForInfoGraphics().otherSources.revenue_sum | money }}
      </text>
      <text
        text-anchor="end"
        title="egyenleg"
        transform="matrix(1 0 0 1 959.1492 241.7745)"
        class="st41 st11 st45"
      >
        {{
          report.convertForInfoGraphics().otherSources.financial_balance | money
        }}
      </text>
      <text
        title="konzerziok"
        text-anchor="middle"
        transform="matrix(1 0 0 1 891.5884 318.9212)"
        class="st10 st11 st12"
      >
        {{ report.convertForInfoGraphics().otherSources.online_conversions }}
      </text>
      <text
        title="leadek"
        text-anchor="middle"
        transform="matrix(1 0 0 1 891.5884 414.6606)"
        class="st10 st11 st12"
      >
        {{ report.convertForInfoGraphics().otherSources.generated_leads }}
      </text>
      <text
        title="offline-eladasok"
        text-anchor="middle"
        transform="matrix(1 0 0 1 891.1948 493.8533)"
        class="st10 st11 st12"
      >
        {{
          report.convertForInfoGraphics().otherSources.offline_converisons
            | decimal
        }}
      </text>
    </g>
  </g>
  <g title="Facebook column">
    <g title="Facebook SUM card">
      <text
        title="kiadas"
        text-anchor="end"
        transform="matrix(1 0 0 1 753.9066 182.0931)"
        class="st34 st11 st45"
      >
        {{ report.convertForInfoGraphics().facebook.marketing_cost | money }}
      </text>
      <text
        title="bevetel"
        text-anchor="end"
        transform="matrix(1 0 0 1 753.9066 211.9341)"
        class="st36 st11 st45"
      >
        {{ report.convertForInfoGraphics().facebook.revenue_sum | money }}
      </text>
      <text
        title="egyenleg"
        text-anchor="end"
        transform="matrix(1 0 0 1 753.9064 241.7745)"
        class="st41 st11 st45"
      >
        {{ report.convertForInfoGraphics().facebook.financial_balance | money }}
      </text>
    </g>
    <g title="funnel">
      <text
        title="konzerziok"
        text-anchor="middle"
        transform="matrix(1 0 0 1 686.3631 318.9212)"
        class="st10 st11 st12"
      >
        {{ report.convertForInfoGraphics().facebook.online_conversions }}
      </text>
      <text
        title="leadek"
        text-anchor="middle"
        transform="matrix(1 0 0 1 686.363 414.6606)"
        class="st10 st11 st12"
      >
        {{ report.convertForInfoGraphics().facebook.generated_leads }}
      </text>
      <text
        title="offline-eladasok"
        text-anchor="middle"
        transform="matrix(1 0 0 1 686.163 493.8533)"
        class="st10 st11 st12 st13"
      >
        {{
          report.convertForInfoGraphics().facebook.offline_converisons | decimal
        }}
      </text>
    </g>
  </g>
  <g title="sum">
    <text
      title="kiadas"
      text-anchor="end"
      transform="matrix(1 0 0 1 366.0714 124.4361)"
      class="st34 st11 st35"
    >
      {{ report.sum().marketing_cost | money }}
    </text>
    <text
      title="bevetel"
      text-anchor="end"
      transform="matrix(1 0 0 1 366.0717 183.3137)"
      class="st36 st11 st35"
    >
      {{ report.sum().revenue_sum | money }}
    </text>
    <text
      title="egyenleg"
      text-anchor="end"
      transform="matrix(1 0 0 1 366.0717 242.5436)"
      class="st41 st11 st35"
    >
      {{ report.sum().financial_balance | money }}
    </text>

    <text
      title="konverziok"
      text-anchor="end"
      transform="matrix(1 0 0 1 333.3463 317.532)"
      class="st10 st11 st42"
    >
      {{ report.sum().online_conversions }}
    </text>
    <text
      title="leadek"
      text-anchor="end"
      transform="matrix(1 0 0 1 333.2471 409.1464)"
      class="st10 st11 st42"
    >
      {{ report.sum().generated_leads }}
    </text>
    <text
      title="offline_eladasok"
      text-anchor="end"
      transform="matrix(1 0 0 1 333.2956 494.3181)"
      class="st10 st11 st42 st43"
    >
      {{ report.sum().offline_converisons | decimal }}
    </text>
  </g>
</svg>
