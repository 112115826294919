import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { IAuthToken } from '../models/auth/IAuthToken';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigService } from './config.service';
import { INewPassword } from '../models/auth/INewPassword';
import { tap } from 'rxjs/operators';
import { ILoginWithTwoFACode } from '../models/auth/ILoginWithTwoFACode';
import { ISignUp } from '../models/auth/ISignUp';
import { IConfirmEmail } from '../models/auth/IConfirmEmail';
import { IForgottPassword } from '../models/auth/IForgotPassword';
import { IUser } from '../models/IUser';
import { IAuthClaims } from '../models/auth/IAuthClaims';
import { IStorageState } from '../classes/storage/IStorageState';
import { LocalStorageState } from '../classes/storage/LocalStorageState';
import { SessionStorageState } from '../classes/storage/SessionStorageState';
import { UserService } from './user.service';
import { UserCollection } from '../models/collections';
import { ILoginResponse } from '../models/auth/ILoginResponse';
import { CustomerService } from './customer.service';
import { RedirectService } from 'src/app/services/redirect.service';



@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  CurrentUserSubject: BehaviorSubject<IUser> = new BehaviorSubject(null);
  Token: IAuthToken;
  LoginEvent = new EventEmitter<User>();
  private _storage: IStorageState = new SessionStorageState();
  private _customAuthRoute: string = 'customers';
  private _tokenString: BehaviorSubject<string> = new BehaviorSubject(null);
  private _jwt: JwtHelperService = new JwtHelperService();

  private _apiUri = `${this._config.apiUrl}/${this._customAuthRoute}/auth`;

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private _user: UserService,
    private _customer: CustomerService,
    private _redirect: RedirectService
  ) {}

  UseLocalStorage(): void {
    this._storage = new LocalStorageState();
  }

  RefresStoredCurrentUserData(): void {
    var currentUser = this._storage.ReadCurrentUser();

    this._user.GetUserById(currentUser.id).subscribe(
      (response: UserCollection) => {
        this._storage.RemoveCurrentUser();
        this._storage.StoreCurrentUser(response[0]);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  NewPassword(model: INewPassword): Observable<any> {
    return this._http.post(`${this._apiUri}/NewPassword`, model);
  }

  get currentUserValue(): User {
    return this.CurrentUserSubject.value;
  }

  get currentAuthToken(): string {
    return this._storage.ReadAuthToken();
  }


  Login(accessToken: string): Observable<boolean> {
    this.UseLocalStorage();
    this._storage.StoreAuthToken(accessToken);
    this._tokenString.next(accessToken);
    this.Token = this._jwt.decodeToken(accessToken);

    return this._http.get(`${this._config.authServiceHost}/LoggedIn`).pipe(
      tap(
        (response: any) => {          
          const currentUser = new User(response.user);          
          this._storage.StoreCurrentUser(currentUser);
          
          this.LoginEvent.emit(currentUser);
          this.CurrentUserSubject.next(currentUser);
        },
        (error: HttpErrorResponse) => {
          console.error(error.status);
        }
      )
    )
  }

  private userDataStorage(currentUser: IUser, accessToken: string): void {
    this._storage.StoreAuthToken(accessToken);
    this._storage.StoreCurrentUser(currentUser);
  }

  LoginWithTwoFACode(model: ILoginWithTwoFACode): Observable<ILoginResponse> {
    return this._http.post(`${this._apiUri}/loginWith2FA`, model).pipe(
      tap(
        (response: ILoginResponse) => {
          console.log(response);
          const currentUser = new User(response.user);
          this.CurrentUserSubject.next(currentUser);
          this.userDataStorage(currentUser, response.token);
          this._tokenString.next(response.token);
          this.Token = this._jwt.decodeToken(response.token);
          this.LoginEvent.emit(currentUser);
        },
        (error) => {}
      )
    );
  }

  SignUp(model: ISignUp): Observable<any> {
    return this._http.post(`${this._apiUri}/registration`, model);
  }

  ConfirmEmail(model: IConfirmEmail): Observable<any> {
    const url =
      this._config.apiUrl +
      '/auth/ConfirmEmail?userId=' +
      model.userId +
      '&token=' +
      encodeURIComponent(model.token);
    return this._http.get(url);
  }

  ForgotPassword(model: IForgottPassword): Observable<any> {
    return this._http.post(`${this._apiUri}/ForgotPassword`, model);
  }

  private _readAuthClaimsFromLocalStorage(): IAuthClaims {
    this.UseLocalStorage();
    const user: IUser = this._storage.ReadCurrentUser();
    const token = this._storage.ReadAuthToken();

    return { user: user, token: token };
  }

  LocalLoginCheck(): boolean {
    const claims = this._readAuthClaimsFromLocalStorage();

    if(claims.token === 'null') return false;

    if (this._jwt.isTokenExpired(claims.token)) {
      this.Logout();

      return false;
    }

    if (claims.user === null) return false;

    return true;
  }

  LocalLogin(): void {
    const claims = this._readAuthClaimsFromLocalStorage();

    this.Token = this._jwt.decodeToken(claims.token);
    this.CurrentUserSubject = new BehaviorSubject(claims.user);
  }

  Logout() {
    this.RemoveUserDataFromBrowserStorage();
    this.CurrentUserSubject.next(null);
    this._redirect.ToAuthServerLoginPage();
  }

  RemoveUserDataFromBrowserStorage(): void {
    this._storage.RemoveAuthToken();
    this._storage.RemoveCurrentUser();
  }
}
