import { Component, Input, OnInit } from '@angular/core';
import { IReport } from '../../models/Interfaces/IReport';

@Component({
  selector: 'app-report-info-graphics',
  templateUrl: './report-info-graphics.component.html',
  styleUrls: ['./report-info-graphics.component.css'],
})
export class ReportInfoGraphicsComponent implements OnInit {
  @Input() report: IReport;
  @Input() i: number;
  constructor() {}

  ngOnInit(): void {}
}
