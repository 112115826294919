<div *ngIf="state !== 'edit' && cost !== undefined">
  <div class="input-group">
    {{ cost | money }} HUF
    <div class="btn-group" role="group">
      <button
        *ngIf="state !== 'setAdCost'"
        class="btn btn-light print-hidden"
        type="button"
        (click)="setEdit()"
        popover="Szerkesztés"
        triggers="hover"
      >
        <i class="fas fa-edit" style="color: goldenrod"></i>
      </button>

      <button
        *ngIf="state === 'setAdCost'"
        class="btn btn-light print-hidden"
        type="button"
        [popover]="editWarning"
        [outsideClick]="true"
      >
        <i class="fas fa-edit" style="color: goldenrod"></i>
      </button>
      <ng-template #editWarning>
        <div>
          <div
            [innerHtml]="
              'A hirdetési költés összege automatán meg lett állapítva. Biztos, hogy át akarja írni?'
            "
          ></div>
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-light"
              (click)="cancelEditWarning()"
            >
              Mégsem
            </button>
            <button type="button" class="btn btn-warning" (click)="setEdit()">
              Igen
            </button>
          </div>
        </div>
      </ng-template>

      <button
        *ngIf="state !== 'untouched' && state !== 'setAdCost'"
        class="btn btn-light print-hidden"
        type="button"
        (click)="setReset()"
        popover="Reset"
        triggers="hover"
      >
        <i class="fas fa-trash-alt" style="color: red"></i>
      </button>
    </div>
  </div>
</div>
<!-- input edit field show -->
<div *ngIf="state === 'edit'">
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      [ngClass]="{ 'is-invalid': isInvalid }"
      placeholder="Marketing cost"
      [value]="cost"
      id="inputMarketingCost"
      aria-describedby="button-addon2"
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary print-hidden"
        type="button"
        (click)="setValue()"
        popover="Ok"
        triggers="hover"
      >
        <i class="far fa-check-circle" style="color: green"></i>
      </button>
    </div>
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary print-hidden"
        type="button"
        (click)="dropEdit()"
        popover="Elvetés"
        triggers="hover"
      >
        <i class="fas fa-times" style="color: red"></i>
      </button>
    </div>
  </div>
</div>
