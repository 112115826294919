import { IReport } from './Interfaces/IReport';
import { IReportRow } from './Interfaces/IReportRow';
import { IReportResponse } from './Interfaces/IReportResponse';
import { IReportForInfoGraphics } from './Interfaces/IReportForInfoGraphics';
import { NullReportRow } from './NullModels/NullReportRow';
import { ReportRow } from './ReportRow';

export class Report implements IReport {
  date: Date;
  rows: IReportRow[] = [];

  constructor(report: IReportResponse) {    
    this.date = new Date(report.date);
    for (var row of report.rows) {
      this.rows.push(
        new ReportRow({
          source_medium: row.sourceMedium,
          online_conversions: row.onlineConversions,
          generated_leads: row.generatedLeads,
          offline_converisons: row.transaction,
          revenue_sum: row.revenueSum,
          marketing_cost: 0,
          financial_balance: 0,
        })
      );
    }
  }

  convertForInfoGraphics(): IReportForInfoGraphics {
    var result: IReportForInfoGraphics = {
      facebook: new NullReportRow('facebook / cpc'),
      googleAds: new NullReportRow('google / cpc'),
      otherSources: {
        source_medium: '',
        online_conversions: 0,
        generated_leads: 0,
        offline_converisons: 0,
        revenue_sum: 0,
        marketing_cost: 0,
        financial_balance: 0,
      },
    };

    for (var row of this.rows) {
      if (row.source_medium === 'facebook / cpc') {
        result.facebook = row;
        continue;
      }
      if (row.source_medium === 'google / cpc') {
        result.googleAds = row;
        continue;
      }

      result.otherSources.online_conversions += row.online_conversions;
      result.otherSources.generated_leads += row.generated_leads;
      result.otherSources.offline_converisons += row.offline_converisons;
      result.otherSources.revenue_sum += row.revenue_sum;
      result.otherSources.marketing_cost += row.marketing_cost;
      result.otherSources.financial_balance += row.financial_balance;
    }

    result.otherSources.revenue_sum / (this.rows.length - 2);

    return result;
  }

  sum(): IReportRow {
    var result: IReportRow = {
      source_medium: '',
      online_conversions: 0,
      generated_leads: 0,
      offline_converisons: 0,
      revenue_sum: 0,
      marketing_cost: 0,
      financial_balance: 0,
    };

    for (var row of this.rows) {      
      result.online_conversions += row.online_conversions;
      result.generated_leads += row.generated_leads;
      result.offline_converisons += row.offline_converisons;
      result.revenue_sum += row.revenue_sum;
      result.marketing_cost += row.marketing_cost;
      result.financial_balance += row.financial_balance;
    }

    result.financial_balance = result.revenue_sum - result.marketing_cost;

    return result;
  }
}
