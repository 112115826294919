import { GlobalHelper } from 'src/app/enums/GlobalHelper.enum';
import { INullReportRow } from '../Interfaces/INullReportRow';

export class NullReportRow implements INullReportRow {
  source_medium: string = GlobalHelper.ReportResultNotApplicable;
  online_conversions: string = GlobalHelper.ReportResultNotApplicable;
  generated_leads: string = GlobalHelper.ReportResultNotApplicable;
  lead_performance: string = GlobalHelper.ReportResultNotApplicable;
  offline_converisons: string = GlobalHelper.ReportResultNotApplicable;
  revenue_sum: string = GlobalHelper.ReportResultNotApplicable;
  marketing_cost: string = GlobalHelper.ReportResultNotApplicable;
  financial_balance: string = GlobalHelper.ReportResultNotApplicable;

  constructor(sourceMedium: string) {
    this.source_medium = sourceMedium;
  }
}
