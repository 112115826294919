import { IAdCost } from './Interfaces/IAdCost';
export class AdCost implements IAdCost {
    sourceMedium: string;
    cost: number;
    yearMonthGroup: Date;

    constructor(adCost: IAdCost = null) {
        if(adCost !== null) {
            this.sourceMedium = adCost.sourceMedium;
            this.cost = adCost.cost;
            this.yearMonthGroup = new Date(adCost.yearMonthGroup);
        }
    }
}