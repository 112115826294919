import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public apiUrl: string = 'https://imp-customers.appspot.com';
  public siteName: string = '';
  public authTokenStorageName: string = 'token';
  public currentUserStorageName: string = 'currentUser';
  public connectorProdutcApi: string = 'https://connector.impaqtor.hu';
  public metricsProductApi: string = 'https://metrics.impaqtor.hu';
  public appId: string = "99b6cc62-9cbb-44fc-9ecf-e5a89587ecca";
  public authServiceHost: string = 'https://auth-service.impaqtor.hu';

  // localhost config
  private _authServiceLocalHost: string = 'http://localhost:5500';
  private _appIdLocalhost: string = 'a7a649be-b4d4-46ad-899c-e99e55a2576d';
  private _localhostServer: string = 'https://localhost:44344';

  constructor() {
    this.setLocalhostConfig();
  }

  private setLocalhostConfig(): void {
    if (this.getHostName() === 'localhost') {
      //this.authServiceHost = this._authServiceLocalHost;
      this.appId = this._appIdLocalhost;
      this.apiUrl = this._localhostServer;
    }
  }

  private getHostName(): string {
    return window.location.hostname;
  }
}
