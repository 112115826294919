import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date_for_report_list',
})
export class DateViewForReportListPipe implements PipeTransform {
  transform(value: Date): string {
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(value);
    const mo = new Intl.DateTimeFormat('hu', { month: 'long' }).format(value);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(value);
    return `${ye}. ${mo}`;
  }
}
