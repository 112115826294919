import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/Services/authentication.service';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
  constructor(private _auth: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentToken = this._auth.currentAuthToken;
    const currentUser = this._auth.currentUserValue;
    
    // if (currentUser && currentToken) {
    //   req = req.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${currentToken}`,
    //     },
    //   });
    // }

    if(currentToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`,
        },
      });
    }

    return next.handle(req);
  }
}
