import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/Services/config.service';


@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(private _router: Router, private _config: ConfigService) {}

  ToLogin(message: string = ''): void {
    if (message !== '') {
      const qp: object = { message: message };
      this._router.navigate(['/login'], { queryParams: qp });
      return;
    }

    this._router.navigate(['/login']);
  }
  ToHome(): void {
    this._router.navigate(['/']);
  }
  ToDashboard(): void {
    this._router.navigate(['/dashboard']);
  }
  ToRegistration(): void {
    this._router.navigate(['/registration']);
  }

  // https://localhost:5001/Identity/Account/Login?appId=
  ToAuthServerLoginPage(): void {
    window.location.href = `${this._config.authServiceHost}/Identity/Account/Login?appId=${this._config.appId}`;
  }
}
