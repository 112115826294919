import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/Services/config.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AdCost } from '../models/AdCost';
import { IAdCost } from '../models/Interfaces/IAdCost';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdCostService {
  AdCosts: BehaviorSubject<AdCost[]> = new BehaviorSubject<AdCost[]>(null);

  constructor(
    private _http: HttpClient,
    private _config: ConfigService
  ) { }

  getAdCosts(): Observable<AdCost[]> {
    return this._http.get(`${this._config.apiUrl}/api/AdCosts/GetAdCosts`)
      .pipe(map((response: IAdCost[]) => {
        var result: IAdCost[] = [];
        for(var cost of response) {
          result.push(new AdCost(cost));
        }
        this.AdCosts = new BehaviorSubject<AdCost[]>(result);
        return result;
      }));
  }
}
