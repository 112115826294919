import { IReportRow } from './Interfaces/IReportRow';
export class ReportRow implements IReportRow {
    source_medium: string;
    online_conversions: number;
    generated_leads: number;
    offline_converisons: number;
    revenue_sum: number;
    marketing_cost: number;
    financial_balance: number;

    constructor(row: IReportRow) {
        Object.assign(this, row);
    }
}