import { Pipe, PipeTransform } from '@angular/core';
import { GlobalHelper } from 'src/app/enums/GlobalHelper.enum';

@Pipe({
  name: 'money',
})
export class MoneyAmountPipe implements PipeTransform {
  transform(value: string | number): string {
    if (value === GlobalHelper.ReportResultNotApplicable)
      return GlobalHelper.ReportResultNotApplicable;

    var num = Number.parseFloat(value.toString()).toLocaleString('hu');

    return Number.parseFloat(value.toString()).toLocaleString('hu');
  }
}
