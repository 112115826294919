  <accordion [isAnimated]="true">
    <accordion-group *ngFor="let log of list">
      <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
        <div class="pull-left float-left">
            <b>Idő:</b> {{ log.createdAt | timeViewForFileUploadLog}} |
            <b>Dátum:</b> {{ log.createdAt | date }} |
            <b>Feltöltötte:</b> {{ log.uploader.email }}
        </div>
        <span class="badge badge-secondary float-right pull-right">
            Tranzakciók: {{ log.createdTransacitonsCount }}
        </span>
      </button>

      <table class="table table-sm table-striped table-borderless">
        <tbody>
          <tr>
            <th scope="row">Feltöltő neve:</th>
            <td colspan="4">{{ log.customer.name }}</td>
          </tr>
          <tr>
            <th scope="row">Sikeresen feltöltött tranzakciók:</th>
            <td>{{ log.createdTransacitonsCount }}</td>
          </tr>
          <tr>
            <th scope="row">Sorok száma:</th>
            <td colspan="2">{{ log.sourceRowCount }}</td>
          </tr>
          <tr>
            <th scope="row">Fájlnév:</th>
            <td colspan="2">{{ log.fileName }}</td>
          </tr>
          <tr>
            <th scope="row">Fájlméret:</th>
            <td colspan="2">{{ log.fileSize }}</td>
          </tr>
          <tr>
            <th scope="row">Fájltípus:</th>
            <td colspan="2">{{ log.fileType }}</td>
          </tr>
          <tr>
            <th scope="row">Hibák:</th>
            <td class="table-success" *ngIf="log.errors.length === 0">Nem volt hiba.</td>
            <td class="table-danger" *ngIf="log.errors.length > 0">
              <ul class="list-group">
                <li
                  class="list-group-item list-group-item-danger"
                  *ngFor="let error of log.errors"
                >
                  {{ error.message }}
                </li>
              </ul>
            </td>            
          </tr>
        </tbody>
      </table>      
    </accordion-group>
  </accordion>
