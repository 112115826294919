<accordion [isAnimated]="true" *ngIf="last">
  <accordion-group [panelClass]="'bg-light'">
    <button
      class="btn btn-link btn-block clearfix text-white bg-primary"
      accordion-heading
      type="button"
    >
      <div class="pull-left float-left">
        <b>Idő:</b> {{ last.createdAt | timeViewForFileUploadLog }} |
        <b>Dátum:</b> {{ last.createdAt | date }} | <b>Feltöltötte:</b>
        {{ last.uploader.email }}
      </div>
      <span class="badge badge-secondary float-right pull-right">
        Tranzakciók: {{ last.createdTransacitonsCount }}
      </span>
    </button>
    <table class="table table-sm table-striped table-borderless">
      <tbody>
        <tr>
          <th scope="row">Feltöltő neve:</th>
          <td colspan="4">{{ last.customer.name }}</td>
        </tr>
        <tr>
          <th scope="row">Sikeresen feltöltött tranzakciók:</th>
          <td>{{ last.createdTransacitonsCount }}</td>
        </tr>
        <tr>
          <th scope="row">Sorok száma:</th>
          <td colspan="2">{{ last.sourceRowCount }}</td>
        </tr>
        <tr>
          <th scope="row">Fájlnév:</th>
          <td colspan="2">{{ last.fileName }}</td>
        </tr>
        <tr>
          <th scope="row">Fájlméret:</th>
          <td colspan="2">{{ last.fileSize }}</td>
        </tr>
        <tr>
          <th scope="row">Fájltípus:</th>
          <td colspan="2">{{ last.fileType }}</td>
        </tr>
        <tr>
          <th scope="row">Hibák:</th>
          <td class="table-success" *ngIf="last.errors.length === 0">Nem volt hiba.</td>
          <td class="table-danger" *ngIf="last.errors.length > 0">
            <ul class="list-group">
              <li
                class="list-group-item list-group-item-danger"
                *ngFor="let error of last.errors"
              >
                {{ error.message }}
              </li>
            </ul>
          </td>            
        </tr>
      </tbody>
    </table>   
  </accordion-group>
</accordion>
