import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from 'util';
import { GlobalHelper } from '../enums/GlobalHelper.enum';

@Pipe({
  name: 'decimal',
})
export class DecimalPipe implements PipeTransform {
  transform(value: string | number): string {
    if (value === GlobalHelper.ReportResultNotApplicable)
      return GlobalHelper.ReportResultNotApplicable;

    return Number.parseFloat(value.toString()).toPrecision(2);
  }
}
